import React from "react";
import PropertyCard from "./properyCard";
import SearchInput from "./search";

const PropertyList: React.FC = () => {
  return (
    <div>
      <SearchInput />
      <div className="card-deck">
        <PropertyCard
          title="HillView"
          description="A hill top camping destinatio"
        />
        <PropertyCard
          title="Good grounds"
          description="camp grounds on a nice location"
        />
      </div>
    </div>
  );
};

export default PropertyList;
