import React from "react";
import DestinationCard from "./destinationCard";
import ooty from "../assets/images/ooty.png";
import kodaikanal from "../assets/images/kodaikanal.jpg";
import coorg from "../assets/images/coorg.png";
import { CardGroup } from "react-bootstrap";

const FeaturedDestination: React.FC = () => {
  return (
    <CardGroup>
      <DestinationCard
        title="Ooty"
        description="visit the serene queen of hills"
        image={ooty}
      />
      <DestinationCard
        title="Kodaikanal"
        description="visit the serene queen of hills"
        image={kodaikanal}
      />
      <DestinationCard
        title="Coorg"
        description="visit the serene queen of hills"
        image={coorg}
      />
    </CardGroup>
  );
};

export default FeaturedDestination;
