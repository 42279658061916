import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const SearchInput: React.FC = () => {
  return (
    <div className="flex justify-content-between align-items-center mb-5">
      <h1>Where to?</h1>
      <p className="fs-5 col-md-8">
        Quickly and easily find campsites for you next recreational and
        adventure camping.
      </p>
      <Form>
        <Form.Group className="input-group input-lg" controlId="formBasicEmail">
          <Form.Control
            type="text"
            placeholder="Coonoor"
            className="form-control-lg"
          />
          <Button variant="primary" type="submit">
            Explore!
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};

export default SearchInput;
