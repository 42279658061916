import React from "react";
import { Link } from "react-router-dom";

const FooterLinks: React.FC = () => {
  return (
    <div className="row g-5">
      <div className="col-md-6">
        <h2>Do you own land?</h2>
        <p>
          List your property in few easy steps without cost. Your listing will
          be avaliable for campers as soon as the information is verified.
        </p>
        <ul className="icon-list ps-0">
          <li className="d-flex align-items-start mb-1">
            <Link to="partner-join" className="btn btn-primary">
              Join
            </Link>
          </li>
        </ul>
      </div>

      <div className="col-md-6">
        <h2>Links</h2>
        <p>Say Hi!</p>
        <ul className="icon-list ps-0">
          <li className="d-flex align-items-start mb-1">
            <a href="mailto:support@campsites.co.in">support@campsites.co.in</a>
          </li>
          <li className="d-flex align-items-start mb-1">
            <a href="blog.campsites.co.in">blog</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FooterLinks;
