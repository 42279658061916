import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";

export type CardInput = {
  title: string;
  description: string;
  image: string;
};

const DestinationCard: React.FC<CardInput> = (props: CardInput) => {
  return (
    <Card style={{ width: "18rem" }}>
      <Card.Img variant="top" src={props.image} />
      <Card.Body>
        <Card.Title> {props.title} </Card.Title>
        <Card.Text>{props.description}</Card.Text>
        <Link to="camps" className="btn btn-primary">
          Check out!
        </Link>
      </Card.Body>
    </Card>
  );
};

export default DestinationCard;
