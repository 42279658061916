import * as React from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/layout";
import Home from "./components/home";
import PropertyList from "./components/propertyList";
import PartnerSignup from "./components/partnerSignup";

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="camps" element={<PropertyList />}></Route>
        <Route path="partner-join" element={<PartnerSignup />}></Route>
      </Route>
    </Routes>
  );
};

export default App;
