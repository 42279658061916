import React from "react";
import SearchInput from "./search";
import FeaturedDestination from "./featuredDestinationRow";
import FooterLinks from "./footerLinks";

const Home: React.FC = () => {
  return (
    <div>
      <div className="row">
        <SearchInput />
      </div>

      <div className="row">
        <FeaturedDestination />
      </div>

      <div className="row">
        <FooterLinks />
      </div>
    </div>
  );
};

export default Home;
