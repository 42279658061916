import React from "react";
import { Card, Col, Row } from "react-bootstrap";

type PropertyCardInput = {
  image?: string;
  title: string;
  description: string;
  caption?: string;
};

const PropertyCard: React.FC<PropertyCardInput> = (
  props: PropertyCardInput
) => {
  return (
    <Card className="mb-3">
      <Row className="no-gutters">
        <div className="col-auto">
          <img src="//placehold.it/200" className="img-fluid" alt="" />
        </div>
        <Col>
          <div className="card-block px-2">
            <h4 className="card-title mt-1">{props.title}</h4>
            <Card.Text className="card-text">{props.description}</Card.Text>
            <a href="#" className="btn btn-primary disabled">
              Book
            </a>
          </div>
        </Col>
      </Row>
      {props.caption && (
        <Card.Footer className="card-footer w-100 text-muted">
          {props.caption}
        </Card.Footer>
      )}
    </Card>
  );
};

export default PropertyCard;
